<template>
  <div class="page-container">
    <el-card class="box-card" style="margin-bottom: 20px">
      <div class="content">
        此页面用于快速测试VR实验的接口，无需下载实验EXE。无需测试此页的布局、交互等功能。
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>实验登录</span>
      </div>
      <div class="content">
        <el-form ref="form1" :model="test1" :rules="test1.rule">
          <el-form-item label="学校：" prop="schoolId">
            <el-select
                v-model="test1.schoolId"
                name="schoolId"
                tabindex="0"
                autocomplete="off"
                style="width:93%;outline: none"
                placeholder="请选择学校"
            >
              <el-option v-for="item in schoolList" :label="item.label" :value="item.value"
                         :key="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="实验id：" prop="experimentId">
            <el-input v-model="test1.experimentId" placeholder="在教师端-实验课程安排-详情弹窗中复制实验id"></el-input>
          </el-form-item>
          <el-form-item label="用户名（账号）：" prop="account">
            <el-input v-model="test1.account"></el-input>
          </el-form-item>
          <el-form-item label="密码：" prop="password">
            <el-input v-model="test1.password"></el-input>
          </el-form-item>
          <el-form-item label="选择教师：" prop="teacherid" v-if="test1.teacherList.length>0">
            <el-select v-model="test1.teacherid" style="width: 100%">
              <el-option v-for="item in test1.teacherList" :value="item.value" :label="item.label"
                         :key="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click="clickBtn1">登录</el-button>
          </el-form-item>
          <el-form-item label="接口返回：">
            <el-input type="textarea" v-model="test1.result"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <el-card class="box-card" style="margin-top: 20px">
      <div slot="header" class="clearfix">
        <span>实验上传分数</span>
      </div>
      <div class="content">
        <el-form ref="form2" :model="test2" :rules="test2.rule">
          <el-form-item label="实验id：" prop="experimentid">
            <el-input v-model="test2.experimentid" placeholder="在教师端-实验课程安排-详情弹窗中复制实验id"></el-input>
          </el-form-item>
          <el-form-item label="学期id：" prop="semesterid">
            <el-input v-model="test2.semesterid" placeholder="在教师端-实验课程安排-学期下拉-修改-详情弹窗中复制学期id"></el-input>
          </el-form-item>
          <el-form-item label="用户id：" prop="userid">
            <el-input v-model="test2.userid"></el-input>
          </el-form-item>
          <el-form-item label="分数：" prop="score">
            <el-input v-model.number="test2.score"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="clickBtn2">提交成绩</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import {SchoolModel} from "@/model/exp/SchoolModel";
import {CommonModel} from "@/model/CommonModel";
import {loginExe, programAddScore} from "@/api/exp/TestApi";
import {msg_err, msg_success} from "@/utils/ele_component";
import {mapState} from "vuex";

export default {
  name: "studentExeTest",
  computed: {
    ...mapState({
      userInfo: state => state.user
    })
  },
  data() {
    return {
      schoolList: [],
      test1: {
        schoolId: "",
        experimentId: "",
        account: "",
        password: "",
        result: "",
        teacherList: [],
        teacherid: null,
        rule: {
          schoolId: [{required: true, trigger: 'blur', message: '请选择学校'}],
          experimentId: [{required: true, trigger: 'blur', message: '请输入实验id'}],
          account: [{required: true, trigger: 'blur', message: '请输入用户名、账号'}],
          password: [{required: true, trigger: 'blur', message: '请输入密码'}],
        }
      },
      test2: {
        experimentid: "",
        semesterid: "",
        userid: "",
        rule: {
          experimentid: [{required: true, trigger: 'blur', message: '请输入实验id'}],
          semesterid: [{required: true, trigger: 'blur', message: '请输入学期id'}],
          userid: [{required: true, trigger: 'blur', message: '请输入用户id'}],
          score: [{required: true, trigger: 'blur', message: '请输入分数'}],
        }
      }
    }
  },
  mounted() {
    this.getSchoolList()
    this.test1.account = this.userInfo.account
  },
  methods: {
    // 获取学校列表
    async getSchoolList() {
      let schoolList = await SchoolModel.getList(0, 0, {})
      let generateListFilter0 = CommonModel.generateListFilterOptions('name', 'id', schoolList)
      this.schoolList = generateListFilter0[0]
    },
    // 点击实验登录按钮
    async clickBtn1() {
      this.$refs.form1.validate(async valid => {
        if (valid) {
          let [result] = await loginExe({
            schoolid: this.test1.schoolId,
            experimentid: this.test1.experimentId,
            account: this.test1.account,
            password: this.test1.password,
            teacherid: this.test1.teacherid
          })
          this.test1.result = JSON.stringify(result.data)
          if (result.code === 20000) {
            let resultData = result.data
            // 判断是否是一个学校存在多个学生账号的登录，返回教师列表
            if (resultData.hasOwnProperty("teacherList")) {
              let teacherList = resultData["teacherList"]
              if (teacherList.length > 0) {
                let teacherListArr = CommonModel.generateListFilterOptions("name", "id", teacherList, false)[0]
                this.test1.teacherList = teacherListArr
                msg_err("账号重复，请先在列表中选择教师")
                return
              } else {
                msg_err("服务器错误")
                return false
              }
            }

            msg_success("登录成功,下方输入框已经自动赋值")
            this.test2.experimentid = result.data.experimentid
            this.test2.semesterid = result.data.semesterid
            this.test2.userid = result.data.userid
            this.test2.token = result.data.token
            this.$forceUpdate()
          }
        }
      });
    },
    // 点击提交分数按钮
    async clickBtn2() {
      this.$refs.form2.validate(async valid => {
        if (valid) {
          let webToken = sessionStorage.getItem("expToken");
          sessionStorage.setItem("expToken", this.test2.token)
          let [result] = await programAddScore({
            experimentid: this.test2.experimentid,
            semesterid: this.test2.semesterid,
            userid: this.test2.userid,
            score: this.test2.score
          })
          sessionStorage.setItem("expToken", webToken)
          if (result.code === 20000) {
            msg_success("提交分数成功")
          }
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
